import  React from 'react';
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay, EffectFade, Navigation, Thumbs} from 'swiper';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import BrandOtherlink from '../../../components/list-brand-otherlink';
import 'swiper/swiper-bundle.min.css';
import Image from '../../../components/Image';

SwiperCore.use([Navigation, Thumbs, EffectFade, Autoplay]);

const MorsoPage = () => (
      <Layout page="brand-page morso">
        <Seo title="MORSO"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="flex items-end md:ml-16">
            <Image filename="logo/morso.png" width={160} />
            <p className="text-3xl ml-4">/ モルソー</p>
          </h2>
        </section>
        <section className="page-content full-layout">
          <div className="main-content">
            <Swiper
                className="page-main-slider"
                loop
                autoplay={{delay: 4000}}
                speed={1000}
                navigation
                centeredSlides
                breakpoints={{
                  768: {
                    slidesPerView: 1.6,
                  },
                }}
            >
              <SwiperSlide>
                <Image className="full-image" filename="brand/morso/slide-01.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <Image className="full-image" filename="brand/morso/slide-02.jpg" />
              </SwiperSlide>
            </Swiper>
            <div className="content-block">
              <div className="md:flex">
                <div className="flex md:w-2/5 md:pr-4">
                  <div className="item-content w-1/2 pr-3">
                    <Link to="/brand/morso/detail/?id=7110CB">
                      <img src="/update/brand/morso/7110CB/item01.png" alt="7110CB" />
                      <p>7110CB</p>
                    </Link>
                  </div>
                  <div className="item-content w-1/2 pl-3">
                    <Link to="/brand/morso/detail/?id=3610CB">
                      <img src="/update/brand/morso/3610CB/item01.png" alt="3610CB" />
                      <p>3610CB</p>
                    </Link>
                  </div>
                </div>
                <div className="flex md:w-2/5 md:px-2">
                  <div className="item-content w-1/2 pr-3">
                    <Link to="/brand/morso/detail/?id=1126CB">
                      <img src="/update/brand/morso/1126CB/item01.png" alt="1126CB" />
                      <p>1126CB</p>
                    </Link>
                  </div>
                  <div className="item-content w-1/2 pl-3">
                    <Link to="/brand/morso/detail/?id=1630CB">
                      <img src="/update/brand/morso/1630CB/item01.png" alt="1630CB" />
                      <p>1630CB</p>
                    </Link>
                  </div>
                </div>
                <div className="item-content md:w-1/5 w-1/2 md:pl-5">
                  <Link to="/brand/morso/detail/?id=2B-R-Standard-CB">
                    <img src="/update/brand/morso/2B-R-Standard-CB/item01.png" alt="2B-R-Standard-CB" />
                    <p>2B-R-Standard-CB</p>
                  </Link>
                </div>
              </div>
              <Link to="/brand/morso/lineup/" className="btn full white mt-12">morso 商品一覧</Link>
            </div>
            <div className="about-area">
              <h2 className="sub-title text-black">ABOUT</h2>
              <div className="text-box first">
                <p className="md:text-3xl text-2xl c-yellow mb-6">
                  <span className="leading-relaxed tracking-widest">170年以上の<br />
                  伝統工芸技術から<br />
                  生み出された薪ストーブ</span>
                </p>
                <p>素材である鋳鉄に対しての深い拘りと信頼を真髄としてきた、170年以上の伝統工芸技術から生み出されるモルソーの薪ストーブ。<br />
                  開発とイノヴェーションという言葉を常に忘れることなく、時代と流行を追い求めながらも絶えず進化し続けたモルソーにとって“伝統“という言葉は”時代遅れ“ではなく、”経験から培った高度な伝統技術“という言葉を意味します。</p>
              </div>
              <div className="md:flex">
                <Image filename="morso/about-01.jpg" />
                <div className="text-box">
                  <p>時代を全く感じさせない飽きのこない容姿と、優れた機能性を兼ね備えたモルソーの薪ストーブは、従来の薪ストーブのイメージを一新し、家具としての機能や役割を超越した一人の親しい友人のような存在感であることを信じております。 </p>
                  <Link to="/brand/morso/about/" className="text-link white en py-0 text-lg mt-8">
                    VIEW MORE
                  </Link>
                </div>
              </div>
            </div>
            <div className="content-block">
              <div className="bg-gray md:flex px-4 py-8">
                <p className="text-2xl c-yellow md:w-2/6 md:mb-6 mb-2">
                  <span className="leading-relaxed">安心と安全を守る。<br className="pc" />厳しい品質検査と保証<br className="pc" />体制</span>
                </p>
                <p className="md:w-10/12">アンデルセンストーブは北欧メーカーの厳密な検査のうえ出荷されています。更に日本に到着後も再度開封し、独自の厳しい数十項目の検査・点検を合格した製品のみ国内に流通しております。<br />製品の保証期間は5年間です。納入時にお渡しする保証書兼取扱説明確認書に従った使用方法で期間内に本体の破損・故障が生じた場合は、部品の無償提供や修理などをおこないます。ただし炉内の部品など消耗品は対象外となりますので、詳しくは納入時にお渡しする保証規定書をご確認ください。</p>
              </div>
            </div>
            <BrandOtherlink />
          </div>
        </section>
        <section className="bread mt-0">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>
              <Link to="/brand/">ブランド</Link>
            </li>
            <li>MORSO</li>
          </ul>
        </section>
      </Layout>
  );

export default MorsoPage;
